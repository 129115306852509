<template>
	<b-modal
		:id="id"
		size="lg"
		centered
		modal-class="student-info-modal"
		body-class="p-0"
		content-class="p-0"
		header-class="p-0"
		footer-class="p-0 border-0 mt-3"
		hide-header
		hide-footer
	>
		<div class="student-info-modal_header pb-4 pt-1 d-flex align-items-center justify-content-between">
			<TextPlay
				:text="$t('gamification.title')"
				color="var(--fontcolor)"
				size="xl"
				weight="semibold"
			/>
			<IconSax
				@click="$bvModal.hide(id)"
				color="var(--fontcolor)"
				name="close-circle"
			/>
		</div>

		<DividerPlay mb="30px" />

		<b-overlay
			:id="'overlay-background-' + id"
			:show="isLoadingFetching"
			bg-color="var(--backgroundcolor)"
			opacity="1"
			rounded="sm"
		>
			<div
				v-if="!isLoadingFetching"
				class="student-info-modal_body px-4"
			>
				<div class="student-info-modal_body_info d-flex">
					<AvatarPlay
						:name="name"
						class="mr-4"
						size="7xl"
						:src="member?.member?.picture"
					/>

					<div class="d-flex flex-column">
						<TextPlay
							weight="semibold"
							size="lg"
							color="var(--fontcolor)"
							:text="member?.member?.name"
						/>

						<div class="d-flex">
							<TextPlay
								size="sm"
								weight="semibold"
								class="mr-1"
								color="var(--text-color)"
								:text="$t('gamification.position')"
							/>
							<TextPlay
								size="sm"
								weight="semibold"
								color="var(--fontcolor)"
								:text="member?.ranking"
							/>
						</div>

						<div class="d-flex">
							<TextPlay
								size="sm"
								class="mr-1"
								weight="semibold"
								color="var(--text-color)"
								:text="`${$t('gamification.punctuation')}:`"
							/>
							<TextPlay
								size="sm"
								weight="semibold"
								color="var(--fontcolor)"
								:text="member?.points"
							/>
						</div>
					</div>
				</div>

				<div class="student-info-modal_body_medal-container">
					<Medal
						v-for="(medal, index) in medals"
						:key="index"
						horizontal
						size="sm"
						:name="medal?.name"
						:picture="medal?.emblem?.picture"
					/>
				</div>
			</div>
		</b-overlay>
	</b-modal>
</template>

<script>
import Medal from "@/components/Medal.vue";
import { mapActions, mapGetters } from "vuex";

export default {
	components: {
		Medal,
	},
	data() {
		return {
			isLoadingFetching: true,
		};
	},
	props: {
		id: {
			type: String,
			required: true,
		},
		memberId: {
			type: String,
			required: true,
		},
	},
	methods: {
		...mapActions({ fetchMember: "gamification/fetchMember", fetchMembersMedals: "gamification/fetchMembersMedals" }),
		async onModalShown(bvModalEvent) {
			this.isLoadingFetching = true;
			const modalId = bvModalEvent.componentId;
			if (modalId === this.id) {
				await this.fetchMember(this.memberId);
				await this.fetchMembersMedals(this.memberId);
			}
			this.isLoadingFetching = false;
		},
		onModalHidden(bvModalEvent) {
			const modalId = bvModalEvent.componentId;
			if (modalId === this.id) {
				this.isLoadingFetching = true;
			}
		},
	},
	computed: {
		...mapGetters({ member: "gamification/getMember" }),
		...mapGetters({ medals: "gamification/getMembersMedals" }),
	},
	mounted() {
		this.$root.$on("bv::modal::shown", this.onModalShown);
		this.$root.$on("bv::modal::hidden", this.onModalHidden);
	},
	beforeDestroy() {
		this.$root.$off("bv::modal::shown", this.onModalShown);
		this.$root.$off("bv::modal::hidden", this.onModalHidden);
	},
};
</script>

<style lang="scss">
.spinner-border {
	color: var(--maincolor);
}

.b-overlay-wrap {
	min-height: 300px;
}

.student-info-modal {
	&_header {
		.isax {
			cursor: pointer;
		}
	}

	&_body {
		&_info {
			.text-play {
				max-width: 40ch;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}

		&_medal-container {
			display: flex;
			flex-wrap: wrap;
			gap: 10px;
			width: 80%;
			margin: auto;
			padding: 20px;
		}

		&_medal-container > * {
			flex: 0 0 calc(50% - 10px); /* 50% da largura menos o gap */
			height: calc(20% - 10px); /* 20% da altura menos o gap */
		}
	}
}

@media (max-width: 768px) {
	.student-info-modal {
		&_body {
			&_medal-container {
				flex-direction: column;
				width: 100%;
				padding: 20px 0;
			}
		}
	}
}
</style>
