<template>
	<div
		:id="`top-ranking-item-${position}`"
		:class="{ 'top-ranking-item--highlited': itsMe, 'skeleton-play': skeleton }"
		class="top-ranking-item d-flex flex-column align-items-center"
	>
		<div class="top-ranking-item_avatar-container">
			<AvatarPlay
				:class="{ 'skeleton-play': skeleton }"
				class="top-ranking-item_avatar-container_avatar"
				:src="avatar"
				:name="name"
				size="7xl"
			/>
			<img
				v-show="!skeleton"
				class="top-ranking-item_avatar-container_cup"
				:src="cupSrc"
			/>
		</div>

		<div class="top-ranking-item_info text-center mt-5">
			<TextPlay
				color="var(--fontcolor)"
				weight="semibold"
				:text="name"
			/>
			<TextPlay
				color="var(--warning-color)"
				weight="semibold"
				:text="$t('gamification.points', { points })"
			/>
		</div>

		<div class="top-ranking-item_medals text-center mt-3">
			<Emblem
				v-for="(medal, index) in medalListSliced"
				:key="index"
				size="xs"
				class="mr-1"
				:src="medal.emblem.picture"
			/>
			<TextPlay
				v-b-modal="id"
				v-if="medalList.length > 5"
				class="mt-2"
				size="xs"
				color="var(--text-color)"
				:text="$t('gamification.see_all')"
			/>
			<StudentGamificationInfoModal
				v-if="medalList.length > 5"
				:memberId="memberId"
				:id="id"
				:name="name"
				:position="position"
				:points="points"
				:medalList="medalList"
			/>
		</div>
	</div>
</template>

<script>
import Emblem from "@/components/Emblem.vue";
import StudentGamificationInfoModal from "@/components/StudentGamificationInfoModal.vue";

export default {
	components: {
		Emblem,
		StudentGamificationInfoModal,
	},
	data() {
		return {
			id: `student-gamification-info-${this._uid}`,
			assetsURL: process.env.VUE_ASSETS_URL,
		};
	},
	props: {
		memberId: {
			type: [Number, String],
		},
		name: {
			type: String,
			default: "",
		},
		avatar: {
			type: String,
			default: null,
		},
		points: {
			type: Number,
			default: 0,
		},
		medalList: {
			type: Array,
			default: () => [],
		},
		position: {
			type: Number,
			default: 1,
		},
		itsMe: {
			type: Boolean,
			default: false,
		},
		skeleton: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		medalListSliced() {
			return this.medalList.slice(0, 5) || [];
		},
		cupSrc() {
			let cupMap = {
				1: `${this.assetsURL}/images/gamification/cups/gold-cup.png`,
				2: `${this.assetsURL}/images/gamification/cups/silver-cup.png`,
				3: `${this.assetsURL}/images/gamification/cups/bronze-cup.png`,
			};
			return cupMap[this.position] || "";
		},
	},
};
</script>

<style lang="scss" scoped>
.top-ranking-item {
	width: 260px;
	height: 170px;
	border-radius: 8px;
	background-color: var(--box-background-color);
	position: relative;

	&--highlited {
		border: 1px solid var(--maincolor);
	}

	&_avatar-container {
		position: absolute;
		transform: translate(-0%, -50%);

		&_avatar {
			border-radius: 50%;
		}

		&_cup {
			position: absolute;
			transform: translate(120%, -80%);
			width: 40px;
			height: 49px;
		}
	}

	&_info {
		.text-play {
			text-wrap: nowrap;
			max-width: 200px;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}
</style>
